// Customizable Area Start
import React from "react";
import BrandStoreController, { ISection, IStoreMenuList, ProductData, Props, configJSON, HomeProductData } from "./BrandStoreController.web";
import { Box, Grid, Typography, Container, styled, Card, CardMedia } from "@material-ui/core";
import { CustomProductSliderTwo, styles } from "./StoreManagement/TemplatePreview.web";
import FooterWeb from "../../landingpage/src/Footer.web";
import HeaderWeb from "../../landingpage/src/Header.web";
import KeyboardArrowRightIcon from "@material-ui/icons//KeyboardArrowRight";
import { starIcon } from "../../catalogue/src/assets";
import { CustomWhiteButton, OrangeButton } from "./CustomizedElementStyle";
import { calculateDiscountPercentage } from "../../catalogue/src/BuyerSearchProduct/ProductListController.web";
import { ExpressLogo } from "../../productquickview/src/assets";
import { Pagination } from "@material-ui/lab";
import { Loader2 } from "../../../components/src/Loader.web";
import ProductList from "../../catalogue/src/BuyerSearchProduct/ProductList.web";
import { getCurrentDate } from "../../../components/src/CommonFunctions";
// Customizable Area End

class BrandStore extends BrandStoreController {
    constructor(props:Props){
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
      // Customizable Area Start
      displayProdDealPrice = (deal_price:string, retailPrice:number ,isDealExists: boolean) => {
        return(
          isDealExists && 
          <>
          <Typography style={styles.priceBox}>
            {configJSON.aedText} <span style={styles.cardPriceText}>{deal_price}</span>
          </Typography>
          {calculateDiscountPercentage(Number(retailPrice), Number(deal_price)) > 0 ?
          <Box style={styles.priceWrapper}>
            <Typography style={styles.price}>{retailPrice}</Typography>
            <Typography style={styles.discountText}>{calculateDiscountPercentage(retailPrice,Number(deal_price))}% {configJSON.offText}</Typography>
          </Box>
          :
          <Box style={styles.priceMargin}>
            <Typography style={styles.price}>&nbsp;</Typography>
            <Typography style={styles.discountText}>&nbsp;</Typography>
          </Box>
          }
          </>
        )
      }
    
      displayProdOfferPrice = (sale_price:string, retailPrice:number ,isOfferExists: boolean) => {
        return(
          isOfferExists && 
          <>
          <Typography style={styles.priceBox}>
            {configJSON.aedText} <span style={styles.cardPriceText}>{sale_price}</span>
          </Typography>
          {calculateDiscountPercentage(Number(retailPrice), Number(sale_price)) > 0 ? 
          <Box style={styles.priceWrapper}>
            <Typography style={styles.price}>{retailPrice}</Typography>
            <Typography style={styles.discountText}>{calculateDiscountPercentage(retailPrice,Number(sale_price))}% {configJSON.offText}</Typography>
          </Box>
          :<Box style={styles.priceMargin}>
          <Typography style={styles.price}>&nbsp;</Typography>
          <Typography style={styles.discountText}>&nbsp;</Typography>
        </Box>
      }
          </>
        )
      }
    
      displayProdRetailPrice (dealActive : boolean, offerActive:boolean, mrp: number | null, retail: number) {
        return (
          <>
          {!dealActive && !offerActive && !mrp &&
          <>
           <Typography style={styles.priceBox}>
           {configJSON.aedText} <span style={styles.cardPriceText}>{retail}</span>
         </Typography>
         <Box style={styles.priceWrapper}>
         <Typography style={styles.price}>&nbsp;</Typography>
       </Box>
       </>
        }
          </>
        )
      }

      displayMrpPrice (dealActive : boolean, offerActive:boolean, mrp: number, retail: number) {
        return (
          <>
          {!dealActive && !offerActive && mrp > 0 &&
          <>
           <Typography style={styles.priceBox}>
           {configJSON.aedText} <span style={styles.cardPriceText}>{retail}</span>
          </Typography>
          <Box style={styles.priceWrapper}>
          <Typography style={styles.price}>{mrp}</Typography>
          </Box>
          </>
        }
          </>
        )
      }

      renderHomeMenuProductsPrice = (item: HomeProductData) => {
        const homeProductData = item.attributes.product_content && item.attributes.product_content.product_attributes;
        const retailPrice = homeProductData && homeProductData.retail_price
        const mrpPrice = homeProductData && homeProductData.mrp
        const dealProdCatalog = item.attributes.deal_catalogues && item.attributes.deal_catalogues[0]
        const catalogueOff = item.attributes && item.attributes.catalogue_offer
        const currentDate = getCurrentDate()
        const prodDealActive = item.attributes.deals[0] && item.attributes.deals[0].status && item.attributes.deals[0].end_date >= currentDate
        const isProdDealExists = item?.attributes?.deal_catalogues[0]?.status === "approved" && prodDealActive && dealProdCatalog?.deal_price
        const isProdOfferActive = catalogueOff && catalogueOff.sale_schedule_to >= currentDate
        const isProdOfferExists = catalogueOff?.status && catalogueOff?.sale_price && isProdOfferActive 
        return(
          <>
          {this.displayProdDealPrice(dealProdCatalog?.deal_price, Number(retailPrice), isProdDealExists as boolean )}
          {this.displayProdOfferPrice(catalogueOff?.sale_price as string, Number(retailPrice), isProdOfferExists as boolean )}
          {this.displayProdRetailPrice(isProdDealExists as boolean, isProdOfferExists as boolean, Number(mrpPrice), Number(retailPrice))}
          {this.displayMrpPrice(isProdDealExists as boolean, isProdOfferExists as boolean, Number(mrpPrice), Number(retailPrice))}
          </>
        )
      }

      renderHome = () => {
        return(
          <>
            {this.state.bannerImage && (
                <Typography data-test-id="bannerImg" component="a" target="_self" href={this.state.bannerUrl} style={styles.bannerSec}>
                  <img style={styles.bannerImg} src={this.state.bannerImage} alt="banner_img" />
                  <Typography style={styles.bannerName}>{this.state.storeBannerName}</Typography>
                </Typography>
            )}
            {this.state.storeSectionOne && (
              <Grid container style={webStyle.secOneContainer}>
                {this.state.storeSectionOne.map((item: ISection) => {
                  return (
                    <>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4} spacing={2} style={styles.card}>
                      <Typography data-test-id="sectionOne" component="a" target="_self" href={item.data.attributes.grid_url} >
                        <img src={item.data.attributes.grid_image} alt="img" style={{...styles.sectionOneImg, height:'180px'}} />
                        <Typography gutterBottom style={styles.cardText} variant="h5" component="h2" align="center">
                          {item.data.attributes.grid_name}
                        </Typography>
                      </Typography>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            )}
            {this.state.storeSectionTwo && (
              <Grid container style={styles.sectionTwoContainer}>
                {this.state.storeSectionTwo.map((item: ISection) => {
                  return (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} spacing={2} style={styles.cardTwo}>
                      <Typography component="a" target="_self" href={item.data.attributes.grid_url}>
                      <img style={styles.gridImage} src={item.data.attributes.grid_image} alt="img" />
                      <Box style={styles.sectionTwoText}>
                        <Typography style={styles.name}>{item.data.attributes.grid_name}</Typography>
                        <Box style={styles.linkBox}>
                          <Typography style={styles.linkText}>
                            {configJSON.learnMoreText} <KeyboardArrowRightIcon style={styles.icon} />{" "}
                            <span style={styles.buy}>
                              {configJSON.buyText}{" "}
                              <Typography>
                                <KeyboardArrowRightIcon style={styles.icon} />
                              </Typography>
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                      <CustomWhiteButton style={{...styles.exploreBtn, ...webStyle.menuLink}}>{configJSON.exploreText}</CustomWhiteButton>
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            )}
            <Box style={webStyle.sliderWrap}>
            <CustomProductSliderTwo {...this.productSliderSettings}>
              {this.state.prodList && this.state.prodList.map((item: HomeProductData, index: number) => {
                const  {product_image, product_rating} = item.attributes
                const {product_title} = item.attributes?.product_content?.product_attributes
                return(
                <Container style={webStyle.container} key={index}>
                  <Card style={webStyle.mainCard}>
                    <Typography style={webStyle.newText}>New</Typography>
                    <CardMedia
                      style={webStyle.cardmedia}
                      component="img"
                      image={product_image}
                      alt={product_title || ""}
                      data-test-id="prodList" onClick={() => this.handleProduct(item.id)} 
                    />
                  </Card>
                  <Box style={styles.desc}>
                    <Typography style={styles.itemName}>{product_title}</Typography>
                    {this.renderHomeMenuProductsPrice(item)}
                    <Box style={styles.bottom}>
                      <img style={styles.expressImg} src={ExpressLogo} alt="express_img" />
                      <Box style={styles.flex}>
                        <Typography style={styles.rating}>
                          {product_rating?.average_rating || 0}{" "}
                          <span style={styles.starSec}>
                            <img src={starIcon} alt="start" />
                          </span>
                        </Typography>
                        <Typography style={styles.offerPrice}>{product_rating?.total_reviews || 0}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Container>
                )
              })}
            </CustomProductSliderTwo>
            </Box>
            {this.state.storeSectionThree && 
              <Grid container spacing={3}>
                {this.state.storeSectionThree.map((item: ISection) => {
                  return (
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Typography component="a" target="_self" href={item.data.attributes.grid_url} >
                      <Box style={styles.productCard}>
                        <img style={styles.sectionThreeImg} src={item.data.attributes.grid_image} alt={"img"} />
                        <Box style={styles.rightSide}>
                          <Box style={styles.right}>
                            <Typography style={styles.gridName}>{item.data.attributes.grid_name}</Typography>
                            <CustomWhiteButton style={{...styles.shopBtn, ...webStyle.menuLink}}>{configJSON.shopNowText}</CustomWhiteButton>
                          </Box>
                        </Box>
                      </Box>
                    </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            }
          </>
        )
      }
      // Customizable Area End
    render() {
      // Customizable Area Start
      const roundCount = this.getCount()

      // Customizable Area End
        return(
          // Customizable Area Start
            <>
            <HeaderWeb navigation={this.props.navigation} id={this.props.id} />
            {this.state.apiInprogress ? (
              <Loader2 loading={this.state.apiInprogress} />
            ) : (
              <></>
            )}
            <Box style={styles.modal} data-test-id="component">
            <Box style={styles.modalWrapper}>
            <Box style={styles.storeSection}>
              <Box style={styles.position}>
                {this.state.storeLogo && 
                <Box style={styles.storeBox}>
                  <Box>
                    <img src={this.state.storeLogo} alt="img" style={styles.logo} />
                    <Typography variant="h6" style={styles.logoName}>
                      {this.state.storeName}
                    </Typography>
                  </Box>
                </Box>
                }
                {this.state.coverImage && 
                  <img src={this.state.coverImage} alt="cover_img" style={styles.storeCover} />
                }
              </Box>
            </Box>
            <Box style={styles.navBar}>
              <Box style={styles.menuContainer}>
                <Typography id="home" onClick={this.handleHomeMenu} style={this.getMenuStyle("home" as string)}>Home</Typography>
                {this.state.storeMenuList.map((item: IStoreMenuList) => {
                  return (
                    <>
                      <Typography data-test-id="storeMenu" style={this.getMenuStyle(item.menuName as string)} onClick={() => this.handleMenuClick(item.id, item.menuName)}>{item.menuName}</Typography>
                    </>
                  );
                })}
              </Box>
            </Box>
            {!this.state.isMenuOpen ? this.renderHome() : 
            <>
            <Grid container spacing={2} style={webStyle.productContainer}>
              {this.state.storeProductList && this.state.storeProductList.map((product: ProductData) => {
                return(
                  <Grid item xs={12} sm={6} md={3} key={product.id}>
                    <ProductList navigation={this.props.navigation} data-test-id="menuProductList" id="product-list-categories" item={product}/>
                  </Grid>
                  )
                })
              }
            </Grid>
            <Pagination
            style={webStyle.paginationStyle}
            data-test-id="pagination"
            page={this.state.pageNumber}
            count={roundCount}
            onChange={(event: React.ChangeEvent<unknown>, value: number) => {
              this.handlePagination(value)
            }}
            shape="rounded"
            siblingCount={0}
            boundaryCount={1}
         />
         </>
            }
          </Box>
        </Box>

        <FooterWeb navigation={this.props.navigation} id={this.props.id} categories={[]} token={""}/>
            </>
            // Customizable Area End
        )
    }
}
// Customizable Area Start
const styles2 = {
  cardContainer: {
    width: "100%",
    border: "1px solid rgb(229, 229, 229)",
    padding: "10px",
    borderRadius: "8px",
    '&:hover': {
      boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.3)"
    }
  },
}

export const webStyle = {
  sliderWrap: {
    margin: "60px -6px 30px",
  },
  mainCard: {
    width: "100%",
    height: "280px",
    borderRadius: "8px",
    border: "1px solid #eee",
    position: "relative" as const,
    '@media (max-width:600px)': {
      padding: 0,
      marginLeft: '0'
    },
  },
  cardImage: {
    alignItems: "center",
    justifyContent: "center",
    objectFit:'cover' as const ,
    height: "100%",
  },
  mobDetails: {
    fontSize: "16px",
    marginTop: "10px",
    marginLeft: "10px",
    color: "#33333a",
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    fontFamily:"Lato, sans-serif",
    fontWeight: 400
  },
  discountedPrice: {
    fontSize: "14px",
    marginTop: "10px",
    marginLeft: "10px",
    color: "#ff6008",
    fontFamily:"Lato, sans-serif",
    fontWeight: 400
  },
  ogPrice: {
    fontSize: "10px",
    marginLeft: "10px",
  },
  rapidImg: {
    width:'67px',
    height: '24px',
    marginLeft:'10px'
  },
  rateBox: {
    padding: "2px 6px",
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    backgroundColor: "#565656",
    color: "#ffffff",
    fontFamily: `"Lato",sans-serif`,
    fontSize: "14px",
    fontWeight: 700 as const,
  },
  starImg: {
    marginLeft: "5px",
  },
  last: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ratingText: {
    fontFamily: `"Lato",sans-serif`,
    fontSize: "14px",
    marginLeft: "5px",
    display: "flex",
    alignItems: "center",
    color: "#ff6008",
    fontWeight: 400 as const,
  },
  offer: {
    color: "#ff6008",
    fontWeight: 700 as const,
    fontFamily: `"Lato",sans-serif`,
    fontSize: "14px",
  },
  mrp: { 
    color: "grey", 
    textDecoration: "line-through", 
    fontSize:'14px', 
    fontWeight: 400, 
    fontFamily: `"Lato",sans-serif`,
  },
  discount: { 
    color: "#33333a", 
    fontSize:'14px', 
    fontWeight: 700, 
    fontFamily: `"Lato",sans-serif`,
  },
  menuLink: {
    cursor: 'pointer'
  },
  activeMenuStyle: {
    fontSize: "14px",
    fontFamily: "Lato, sans-serif",
    fontWeight: 600,
    color: "#fff",
    textTransform: "uppercase" as const,
    margin: "0 40px",
    textDecoration:'underline' as const,
    cursor: 'pointer',
    textAlign: "center" as const
  },
  inactiveMenuStyle: {
    fontSize: "14px",
    fontFamily: "Lato, sans-serif",
    fontWeight: 500,
    color: "#fff",
    textTransform: "uppercase" as const,
    margin: "0 40px",
    cursor: 'pointer',
    textAlign: "center"  as const
  },
  secOneContainer: {
    margin: "30px 0",
    gridGap: "12px",
    flexWrap: "nowrap" as const
  },
  paginationStyle: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "center"
  },
  productContainer: {
    padding: '0 50px'
  },
  newBtn: {
    width: "20%",
    textAlign: "center" as const,
    marginLeft: "78%",
    background: "#fb620f",
    color: "white",
    borderRadius: "3px",
    fontSize: "9px",
    padding: "1.5px",
    position: "absolute" as const ,
    top: "5px",
  },
  container: {
    width: "100%",
    border: "1px solid rgb(229, 229, 229)",
    padding: "10px",
    borderRadius: "8px",
    '&:hover': {
      boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.3)"
    }
  },
  cardmedia: {
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    objectFit:'contain' as const ,
    cursor:'pointer'
  },
  newText: {
    width: "20%",
    textAlign: "center" as const,
    marginLeft: "78%",
    background: "#fb620f",
    color: "white",
    borderRadius: "3px",
    fontSize: "9px",
    padding: "1.5px",
    position: "absolute" as const ,
    top: "5px",
  }
}

const ContainerBox = styled(Container)({
  width: "100%",
    border: "1px solid rgb(229, 229, 229)",
    padding: "10px",
    borderRadius: "8px",
    '&:hover': {
      boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.3)"
    }
})

export default BrandStore
// Customizable Area End