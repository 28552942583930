import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const calculateDiscountPer = (mrpValue:number, retailPriceValue:number) => {
    const discount = ((mrpValue - retailPriceValue) / mrpValue) * 100;
    return Number(discount.toFixed(2)); 
};

export const configJSON = require("../src/config.js")

export interface IProdRes {
  id: string,
  attributes: {
    product_title: string | null,
    product_image: string,
    product_rating: {
       average_rating: number, 
       total_reviews: number
    } | null;
    product_content: {
      product_attributes: {
        mrp?: string | number | null,
        retail_price: string | number | null
      }
    } | null;
    deal_catalogues: {
      deal_price: string,
      status: string
    }[];
    deals: {
      status: boolean,
      end_date: string
    }[],
    catalogue_offer: {
      sale_price: string,
      status: boolean,
      sale_schedule_to: string
    } | null
  }
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  product:IProdRes;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class ListViewProductController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
    // Customizable Area End
  }

  // Customizable Area Start
  handleProduct = (prodId: string) => {
    this.props.navigation.navigate("ProductQuickview", {id: prodId})
  }
  // Customizable Area End
}
