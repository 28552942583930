import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { GetCookies } from "../../../framework/src/WebUtilities";
import { logoImage, emailImage, fbImage, linkdinImage, tiktokImage, whatsappImage, bitmapsImage, originalsImage, manLaptopImage, hourGlassManImage } from "./assets";
import { ICheckBuyerSessionData } from "./Interfaces";
import Cookies from "js-cookie";
import { setStorageData } from "framework/src/Utilities";
import { toast } from "react-toastify";
export const logoImg = logoImage;
export const emailImg = emailImage;
export const fbImg = fbImage;
export const linkdinImg = linkdinImage;
export const tiktokImg = tiktokImage;
export const whatsappImg = whatsappImage;
export const bitmapsImg = bitmapsImage;
export const originalsImg = originalsImage;
export const manLaptopImg = manLaptopImage;
export const hourGlassManImg = hourGlassManImage;
interface DocumentsData {
  id: string;
  type: string;
  attributes: {
    document_type: string;
    document_name: string;
    vat_reason: string;
    account_no: string;
    iban: string;
    bank_address: string;
    name: string;
    bank_name: string;
    swift_code: string;
    approved: boolean | null;
    rejected: boolean | null;
    reason_for_rejection: string;
    account_id: number;
    document_files: string[];
  };
}
interface SuccessResponse {
  data: DocumentsData[];
  meta: {
    message: string;
  };
}

interface ProductAttribute {
  product_title: string;
  product_image: string;
  product_content: {
      product_attributes: {
          product_title: string;
      }
  }
}

interface TrendingProduct {
  data: {
      id: string;
      type: string;
      attributes: ProductAttribute;
  };
}

export interface BannerAttributes {
  id: number;
  title: string;
  description: string;
  button_text: string;
  button_link: string;
  banner_image: string;
  catalogue: {
    data: {
      id: string
    }
  } | null;
  sub_category: {
    data: {
      id: string,
      attributes: {
        id: number,
        name: string,
        relation: string,
        parent_id: number
      }
    }
  } | null,
  category: {
    data: {
      id: string,
      attributes: {
        id: number,
        name: string
      }
    }
  } | null;
  deal: {
    data: {
      id: string
    }
  } | null;
}

export interface SingleBanner {
  id: string;
  type: string;
  attributes: BannerAttributes;
}
interface MicroCategory {
  id: number;
  name: string;
}

interface MiniCategory {
  id: number;
  name: string;
  micro_categories: MicroCategory[];
}

interface SubCategory {
  id: number;
  name: string;
  mini_categories: MiniCategory[];
}

interface CategoryAttributes {
  id: number;
  name: string;
  category_image: string;
  header_image: string | null;
  sub_categories: SubCategory[];
}

export interface Category {
  id: string;
  type: string;
  attributes: CategoryAttributes;
}

interface ICategory {
  data: Category[]
}

export interface HeaderSlide {
  id: string;
  type: string;
  attributes: BannerAttributes
}

interface IHeaderSlideshow {
  data: HeaderSlide[];
}

export interface IWeeklyDeals {
  id: string,
  caption: string,
  discount_percent: string, 
  url: string,
  bg_image: string,
  deal: {
    id: string
  }
}

interface IDeals {
  id: string,
  attributes: {
    id: number | null,
    start_time: string,
    end_time: string,
    status: boolean,
    weekly_deals: IWeeklyDeals[]
  }
}

interface DealsRes {
  data: IDeals
}

export interface ICatalogueData {
  data: {
    id: string,
    attributes: {
      product_title: string,
      product_image: string
    }
  }
}

export interface IFavBookList {
  attributes: {
    title: string,
    catalogues: ICatalogueData[]
  }
}

interface FavBookRes {
  data: IFavBookList
}

interface ISingleBannerRes {
  data: SingleBanner[]
}

interface IProductData {
  id:string,
  attributes: {
    sale_ad_image: string,
    catalogues: TrendingProduct[]
  }
}

interface ProductRes {
  data: IProductData[]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:{
    carousal_image:string;
    carousal_image_container:string;
    itemSliderWrraper:string;
    trendingTemplate?:string;
    trandingTemlateImg?: string;
    itemBoxWrraper:string;
    bannerContentWrapper:string;
    weeklyText:string;
    footerContainer:string;
    textStyle: string;
    dealTopBox?: string;
    categoryPage?:string;
    trendingBack2?:string;
    dualSliderTxtBack?:string;
    weeklyHomeWrapper?:string;
    rightText?: string,
    textBox?: string
  }
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sellerId: string;
  token: string;
  showStartRegistrationBanner: boolean;
  showWelcomeBackBanner: boolean;
  showCongratsBanner: boolean;
  landingPage: string;
  buyer_token: string;
  buyer_lastname: string;
  openProfileMenu: boolean;
  userProfile: boolean;
  headerSlideShow: HeaderSlide[] | null;
  categorySlideShow: Category[];
  singleBannerData: SingleBanner[];
  leftBannerData: SingleBanner[];
  rightBannerData: SingleBanner[];
  middleBannerData: SingleBanner[];
  footerBannerData: SingleBanner[];
  middleBannerOneLink : string,
  middleBannerTwoLink: string,
  footerButtonLink: string,
  productListOne: TrendingProduct[];
  productListTwo: TrendingProduct[];
  days: number | null;
  hours: number | null;
  minutes: number | null;
  seconds: number | null;
  startTime: string,
  endTime: string,
  dealsList: IDeals ,
  isDealPresent: boolean,
  favBookList: IFavBookList,
  salesBannerOne: string,
  salesBannerTwo: string,
  trendingProductsLoading: boolean,
  categoryLoading: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSellerDocsStatusCallId: string = "";
  apiCheckBuyerSessionCallId: string = "";
  apiHeaderSlideshow: string = "";
  apiCategorySlideshow: string = "";
  getHeaderSingleBannerApiCallId: string = "";
  getLeftBannerApiCallId: string = "";
  getRightBannerApiCallId: string = "";
  getMiddleBannerApiCallId: string = "";
  getFooterBannerApiCallId: string = "";
  getProductOneApiCallId: string = "";
  getProductTwoApiCallId: string = "";
  getWeeklyDealsApiCallId: string = "";
  getFavBooksApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.NavigationPayLoadMessage), getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      sellerId: "",
      token: "",
      showStartRegistrationBanner: false,
      showWelcomeBackBanner: false,
      showCongratsBanner: false,
      landingPage: "HomePage",
      buyer_token: "",
      buyer_lastname: "",
      openProfileMenu: false,
      userProfile: false,
      headerSlideShow: [],
      categorySlideShow: [],
      singleBannerData: [],
      leftBannerData: [],
      rightBannerData: [],
      middleBannerData: [],
      footerBannerData: [],
      middleBannerOneLink: "",
      middleBannerTwoLink: "",
      footerButtonLink: "",
      productListOne: [],
      productListTwo: [],
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      startTime: '',
      endTime: '' ,
      dealsList : {
        id: '',
        attributes: {
        id: null,
        start_time: '',
        end_time: '',
        status: true,
        weekly_deals: [{
          id: '',
          caption: '',
          discount_percent: '',
          bg_image: '',
          url: '',
          deal: {
            id: ''
          }
        }]
        }
      },
      isDealPresent: false,
      favBookList: {
        attributes: {
          title: '',
          catalogues: [{
            data: {
            id: '',
            attributes: {
              product_title: '',
              product_image: ''
            }
          }
          }]
        }
      },
      salesBannerOne: '',
      salesBannerTwo: '',
      trendingProductsLoading: false,
      categoryLoading: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      this.handleSuccessResponse(responseJson, apiRequestCallId);
      // apiCategorySlideshow
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSuccessResponse = (responseJson:unknown, apiRequestCallId: string) => {
    switch (apiRequestCallId) {
      case this.getSellerDocsStatusCallId:
        this.handleResponseData(responseJson as DocumentsData);
        break;

      case this.apiCheckBuyerSessionCallId:
        this.handleCheckBuyerSessionResponse(responseJson as ICheckBuyerSessionData);
        break;

      case this.apiHeaderSlideshow:
        this.handleLandingPageBodyContentResponse(responseJson as IHeaderSlideshow);
        break;

      case this.apiCategorySlideshow:
        this.handleCategoryResponse(responseJson as ICategory);
        break;

      case this.getHeaderSingleBannerApiCallId:
        const singleBannerRes = responseJson as ISingleBannerRes
        this.setState({ singleBannerData: singleBannerRes.data });
        break;

      case this.getLeftBannerApiCallId:
        const leftBannerRes = responseJson as ISingleBannerRes
        this.setState({ leftBannerData: leftBannerRes.data });
        break;

      case this.getRightBannerApiCallId:
        const rightBannerRes = responseJson as ISingleBannerRes
        this.setState({ rightBannerData: rightBannerRes.data });
        break;

      case this.getMiddleBannerApiCallId:
        const middleBannerRes = responseJson as ISingleBannerRes
        this.setState({ middleBannerData: middleBannerRes.data, middleBannerOneLink: middleBannerRes.data[0]?.attributes?.button_link, middleBannerTwoLink: middleBannerRes.data[1]?.attributes?.button_link });
        break;

      case this.getFooterBannerApiCallId:
        const FooterBannerRes = responseJson as ISingleBannerRes
        this.setState({ footerBannerData: FooterBannerRes.data, footerButtonLink: FooterBannerRes.data[0]?.attributes?.button_link });
        break;

      case this.getProductOneApiCallId : 
      const productRes = responseJson as ProductRes
        this.setState({
          productListOne: productRes?.data[0].attributes.catalogues, 
          salesBannerOne: productRes.data[0].attributes.sale_ad_image,
          trendingProductsLoading: false
        })  
        break;

      case this.getProductTwoApiCallId : 
      const productTwoRes = responseJson as ProductRes
      this.setState({productListTwo: productTwoRes.data[0].attributes.catalogues, salesBannerTwo: productTwoRes.data[0].attributes.sale_ad_image})  
      break; 
      
      case this.getWeeklyDealsApiCallId : 
        this.handleWeeklyDealRes(responseJson as DealsRes)
        break;

      case this.getFavBooksApiCallId : 
        this.handleFavBooksRes(responseJson as FavBookRes);
        this.handleScrollToFooter();
        removeStorageData("scrollToFooter")
        break;
      default:
        break;
    }
  };

  handleScrollToFooter = async() => {
    const scrollToFooter = await getStorageData("scrollToFooter");
    if (scrollToFooter === 'true') {
    const footerElement = document.getElementById('footer');
    if (footerElement) {
      footerElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  }
  handleFavBooksRes = (responseJson: FavBookRes) => {
    if(responseJson.data) {
      this.setState({favBookList: responseJson.data})
    }
  }

  handleWeeklyDealRes = (responseJson: DealsRes) => {
    if(responseJson.data) {
      this.setState({startTime:responseJson.data?.attributes?.start_time , endTime: responseJson.data?.attributes?.end_time, dealsList : responseJson.data})
      let interval: ReturnType<typeof setInterval>
      interval = setInterval(() => {
        const countdownDate = new Date(responseJson.data?.attributes?.end_time).getTime(); 
        const currentTime = new Date().getTime();
        const distance = countdownDate - currentTime;
        this.setState({isDealPresent: distance < 1000 ? false : true})
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        this.setState({
          days,
          hours,
          minutes,
          seconds
        });
  
        if (distance < 0) {
          clearInterval(interval);
        }
      }, 1000);
    }
  }

  handleLandingPageBodyContentResponse = (resposeJson: unknown) => {
    const successResponse = resposeJson as IHeaderSlideshow;

    this.setState({ headerSlideShow: successResponse.data });
  };
  handleCategoryResponse = (resposeJson: unknown) => {
    const successResponse = resposeJson as ICategory;

    this.setState({ categorySlideShow: successResponse.data, categoryLoading: false });
  };
  handleCheckBuyerSessionResponse = (responseJson: unknown) => {
    const successData = responseJson as ICheckBuyerSessionData;
    if ("data" in successData) {
      this.setState({ buyer_lastname: successData.data.attributes.last_name });
    }
  };

  handleResponseData = async(responseJson: any) => {
    try {
      const successResponse = responseJson as SuccessResponse;
      const isAccountCreated = await getStorageData("isAccountCreated")
      if (successResponse.data.length < 5) {
        await setStorageData("isAccountCreated", true)
        this.setState({ showStartRegistrationBanner: true });
        if(!isAccountCreated) {
          toast.success('Seller and Buyer Account is Created', {
            progress: undefined,
            autoClose: 3000, // milliseconds
            position: "top-right",
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            pauseOnHover: true
          });
        }
      }
      if (successResponse.data.length >= 5 ) {
        const documents = successResponse.data.reduce((newArr: unknown[], value: DocumentsData) => [...newArr, value.attributes.approved], []);
        if (documents.includes(true) && !documents.includes(null) && !documents.includes(false)) {
          await setStorageData("seller_documents_verified", true)
          return this.setState({ showCongratsBanner: true });
        }
        if (documents.includes(false) || documents.includes(null)) {
          return this.setState({ showWelcomeBackBanner: true });
        }
      }
    } catch (error) {}
  };

  handleLandingPageBodyContent = async () => {
    const headers = {
      "Content-Type": configJSON.contentType,
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiHeaderSlideshow = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.headerSlideshowApiEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSellerDocumentsStatus = () => {
    const headers = {
      "Content-Type": configJSON.contentType,
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSellerDocsStatusCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiGetSellerDocumentsStatus + this.state.sellerId + "/seller_documents");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleBannerOnClickAction = () => {
    if (this.state.showStartRegistrationBanner) {
      const message: Message = new Message(getName(MessageEnum.NavigationBulkUploadingAdapterMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
    if (this.state.showWelcomeBackBanner) {
      this.handleGoToSellerDashboard();
    }
    if (this.state.showCongratsBanner) {
      this.props.navigation.navigate("SellerHomeDashboard", {path: "products"})
    }
  };

  handleGoToSellerDashboard = () => {
    this.props.navigation.navigate("SellerDashboard", {path: 'myprofile'})
  };

  onSellerLandingPageLoad = async () => {
    const token = await getStorageData("authToken");
    const sellerId = await getStorageData("seller_id");
    this.setState({ token, sellerId });
    this.getSellerDocumentsStatus();
    if(token) {
      this.checkBuyerSession(token);
    }
  };

  handleCheckLoginSession = () => {
    const buyer_token: string = GetCookies("ba") || "";
    if (buyer_token) {
      this.setState({ buyer_token: buyer_token });
      this.checkBuyerSession(buyer_token);
    }
  };

  checkBuyerSession = (buyer_token: string) => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const headers = { "Content-Type": configJSON.contentType };
    this.apiCheckBuyerSessionCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.checkBuyerSession + buyer_token);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async componentDidMount(): Promise<void> {
    this.handleCheckLoginSession();
    this.handleLandingPageBodyContent();
    this.handleCategory();
    this.getHeaderSingleBanner();
    this.getLeftBanner();
    this.getRightBanner();
    this.getMiddleSingleImages();
    this.getFooterBanner();
    this.getTrendingProductsOne();
    this.getTrendingProductsTwo();
    this.getWeeklyDeals();
    this.getFavBooks();
    this.handleScrollToFooter()
    this.onSellerLandingPageLoad()
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    window.addEventListener('popstate', () =>  {
      if(window.location.pathname.split('/')[1] === "checkout") {
        this.props.navigation.navigate("Home")
      }
    })
  }

  handleCategory = async () => {
    this.setState({
      categoryLoading: true
    })

    const buyer_token = GetCookies("ba") || "";

    const headers = {
      "Content-Type": configJSON.contentType,
      token: buyer_token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiCategorySlideshow = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_categories/categories");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getHeaderSingleBanner = () => {
    const headers = {
      "Content-Type": configJSON.contentType,
      token: this.state.token
    };
    const singleMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getHeaderSingleBannerApiCallId = singleMessage.messageId;
    singleMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getHeaderSingleImgApiEndPoint);
    singleMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    singleMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(singleMessage.id, singleMessage);
  };

  getLeftBanner = () => {
    const headers = {
      "Content-Type": configJSON.contentType,
      token: this.state.token
    };
    const singleMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getLeftBannerApiCallId = singleMessage.messageId;
    singleMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getLeftBannerImgApiEndPoint);
    singleMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    singleMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(singleMessage.id, singleMessage);
  };

  getRightBanner = () => {
    const headers = {
      "Content-Type": configJSON.contentType,
      token: this.state.token
    };
    const singleMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getRightBannerApiCallId = singleMessage.messageId;
    singleMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getRightBannerImgApiEndPoint);
    singleMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    singleMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(singleMessage.id, singleMessage);
  };

  getMiddleSingleImages = () => {
    const headers = {
      "Content-Type": configJSON.contentType,
      token: this.state.token
    };
    const singleMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getMiddleBannerApiCallId = singleMessage.messageId;
    singleMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getMiddleBannerImgApiEndPoint);
    singleMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    singleMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(singleMessage.id, singleMessage);
  };

  getFooterBanner = () => {
    const headers = {
      "Content-Type": configJSON.contentType,
      token: this.state.token
    };
    const singleMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getFooterBannerApiCallId = singleMessage.messageId;
    singleMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getFooterBannerImgApiEndPoint);
    singleMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    singleMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(singleMessage.id, singleMessage);
  };

  getTrendingProductsOne = () => {
    this.setState({
      trendingProductsLoading: true
    })

    const headers = {
      "Content-Type": configJSON.contentType,
      token: this.state.token
    };
    const singleMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProductOneApiCallId = singleMessage.messageId;
    singleMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getProductOneApiEndPoint);
    singleMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    singleMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(singleMessage.id, singleMessage);
  };

  getTrendingProductsTwo = () => {
    const headers = {
      "Content-Type": configJSON.contentType,
      token: this.state.token
    };
    const singleMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProductTwoApiCallId = singleMessage.messageId;
    singleMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getProductTwoApiEndPoint);
    singleMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    singleMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(singleMessage.id, singleMessage);
  };

  getWeeklyDeals = () => {
    const headers = {
      "Content-Type": configJSON.contentType,
      token: this.state.token
    };
    const singleMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getWeeklyDealsApiCallId = singleMessage.messageId;
    singleMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getWeeklyDealsApiEndPoint);
    singleMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    singleMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(singleMessage.id, singleMessage);
  };

  getFavBooks = () => {
    const headers = {
      "Content-Type": configJSON.contentType,
      token: this.state.token
    };
    const singleMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getFavBooksApiCallId = singleMessage.messageId;
    singleMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getFavBooksApiEndPoint);
    singleMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    singleMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(singleMessage.id, singleMessage);
  };

  setColor = (index: number) => {
    if (index === 0) return "#6CB8B2";
    if (index === 1) return "#C7AC9E";
    if (index === 2) return "#CABEAF";
  };

  roundOffPercent = (number: string) => {
    if (Number(number) % 1 !== 0) {
      return Number(number).toFixed(2);
    } else {
      return Math.round(Number(number)); 
    }
  }

  redirectToWeeklyDeal = (dealId: string) => {
    this.props.navigation.navigate("WeeklyDeals", {  id : dealId })
  }

  handleBannerLinks = async(item:HeaderSlide) => {
    if (item.attributes.category && item.attributes.category.data) {
      this.props.navigation.navigate("BuyerExploreProducts", { id: item.attributes.category.data.id, type: item.attributes.category.data.attributes.name });
    }
    if (item.attributes.sub_category && item.attributes.sub_category.data) {
      const type = "subCatagoryIds="
      await setStorageData("subCatagoryIds",item.attributes.sub_category.data.attributes.id )
      this.props.navigation.navigate("BuyerExploreProducts",  {id: item.attributes.sub_category.data.attributes.parent_id, type: `${item.attributes.sub_category.data.attributes.relation}/${type}${item.attributes.sub_category.data.attributes.id}`});
    }
    if (item.attributes.catalogue && item.attributes.catalogue.data) {
      this.props.navigation.navigate("ProductQuickview", { id: item.attributes.catalogue.data.id });
    }
    if (item.attributes.deal && item.attributes.deal.data) {
      this.props.navigation.navigate("WeeklyDeals", {id: item.attributes.deal.data.id})
    }
  }

  handleCategorySlideShow = (categoryId:string, name: string) => {
    this.props.navigation.navigate("BuyerExploreProducts",  {id: categoryId, type: name});
  }

  handleTrendingProducts = (catalogId:string) => {
    this.props.navigation.navigate("ProductQuickview", { id: catalogId});
  } 
  // Customizable Area End
}
