//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import { GetCookies } from "../../../framework/src/WebUtilities";
import { createRef } from 'react';
import { ICheckUserInfoSuccessResponse, } from "../../dashboard/src/SellerDashboardComponents/Interfaces";

interface ISingleAddress {
    attributes: {
        first_name: string,
        last_name: string,
        address_1: string,
        address_2: string,
        state: string,
        city: string,
        phone_number: string,
        zip_code: string,
        is_default: boolean
    }
}

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    userId: string;
    toShow: (data: boolean) => void;
    singleAddressInfo: ISingleAddress | string;
    setAddress?: (address:string) => void
    // Customizable Area End
}
interface InputFieldErr {
    [key: string]: string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    match: {
        props: {
            type: string
        }
    },
    classes: {
        addressHeader: string,
        labelText: string,
        defaultAdd: string,
        inputFiled: string,
        defaultText: string,
        checkBoxContainer: string
    },
    toShow: (data: boolean) => void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    firstName: string;
    lastName: string;
    address: string;
    address2: string;
    mobileNo: string;
    flat: string;
    street: string;
    area: string;
    landMark: string;
    city: string;
    state: string;
    zip: string;
    defaultAddress: boolean;
    addressType: string;
    token: string,
    userId: string;
    successMsg1: string;
    errorMessages1: string[];
    inputFieldError: InputFieldErr;
    showAddressForm: boolean;
    userAddresList: Array;
    addressId: string,
    checkBox: boolean,
    menuOpen:boolean;
    mapCenter: {
        lat: number,
        lng: number
    },
    mapCoOrdinates: {
        lat: number,
        lng: number
    },
    google: typeof google,
    setAddress: string,
    addressErr: string
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class UserAddressController extends BlockComponent<
    Props,
    S,
    SS
> {
    apiCallIdSearchUsrList: string = "";
    apiCallIdGetMessageCount: string = "";
    apiCallIdSendMessage: string = "";
    apiCallIdAllMessage: string = "";
    apiUpdateBuyerProfileCallId: string = "";
    apiGetAllBuyerInfoId: string = "";
    formRef : React.RefObject<HTMLDivElement>

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        let attributes
        if (this.props.singleAddressInfo !== undefined) {
            attributes = this.props.singleAddressInfo.attributes
            this.handlePlacesAutoCompleteSelect(attributes?.address_1)
        }
        const checkBoxVal = attributes?.is_default ? attributes?.is_default : false
        
        this.state = {
            firstName: attributes?.first_name || "",
            lastName: attributes?.last_name || "",
            address: attributes?.address_1 || "",
            address2: attributes?.address_2 || "",
            mobileNo: attributes?.phone_number ? this.setPhoneNumber(attributes.phone_number) : "" ,
            zip: attributes?.zip_code || "",
            street: "",
            area: "",
            landMark: "",
            city: attributes?.city || "",
            state: attributes?.state || "",
            defaultAddress: attributes?.is_default,
            addressType: "",
            token: "",
            userId: "",
            menuOpen:false,
            successMsg1: "",
            errorMessages1: [],
            inputFieldError: {
                "First Name": "",
                "Last Name": "",
                "Address 1": "",
                "Address 2": "",
                "Phone number": "",
                "Flat / Building no.": "",
                "Street": "",
                "Area": "",
                "Landmark": "",
                "City": "",
                "State": "",
                "Zip code": "",
                "Default Address": "",
                "Address Type": ""
            },
            showAddressForm: false,
            userAddresList: [],
            addressId: '' ,
            checkBox: checkBoxVal,
            mapCenter: {
                lat: 0,
                lng: 0
            },
            mapCoOrdinates: {
                lat: 0,
                lng: 0
            },
            google: window.google,
            setAddress: '',
            addressErr: ''
        }
        this.formRef  = createRef()
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.apiUpdateBuyerProfileCallId) {
                    this.handleBuyerProfileUpdateResponse(responseJson)

                }
                if (apiRequestCallId === this.apiGetAllBuyerInfoId) {
                    this.userAddressListResponse(responseJson)

                }
            }
        }

        // Customizable Area End
    }

    // Customizable Area Start
    handleMenuOpen = () => {
        this.setState({ menuOpen: true });
      };
    
      handleMenuClose = () => {
        this.setState({ menuOpen: false });
      };

    handleBuyerProfileUpdateResponse(responseJson: unknown) {
        const successData = responseJson as ICheckUserInfoSuccessResponse;
        if ("data" in successData) {
            this.formRef.current?.scrollIntoView({behavior:"instant", block: 'start'})
            this.setState({
                successMsg1: "Successfully updated your Address!", errorMessages1: [], firstName: "",
                lastName: "",
                mobileNo: "",
                address: "",
                address2: "",
                city: "",
                state: "",
                zip: "", });
            this.props?.toShow(false)
        }
        else {
            
            this.setState({ errorMessages1: responseJson.errors
            , successMsg1: "" });
        }
        

    };

    userIdGetter(userID: string) {
        const accountId = GetCookies("accountId")
        this.setState({ userId: accountId })
    }
    


    fullNameValidator(value: string) {
        this.setState({ firstName: value })
        if (value?.length === 0) {
            this.setState((previous: S) => {
                return { ...previous, inputFieldError: { ...previous.inputFieldError, "First Name": "is Required" } }
            })
        }
        
        else if (!(/^[a-zA-Z\s]+$/).test(value)) {
            this.setState((previous: S) => {
                return { ...previous, inputFieldError: { ...previous.inputFieldError, "First Name": "should have only alphabets" } }
            })
        }

        else if (value?.length > 0 && value?.length < 25){
            this.setState((previous: S) => {
                return { ...previous, inputFieldError: { ...previous.inputFieldError, "First Name": "" } }
            })
        }
 
        else if (value?.length > 25) {
            this.setState((previous: S) => {
                return { ...previous, inputFieldError: { ...previous.inputFieldError, "First Name": "should have max 25 characters" } }
            })
        }
        
    }
    lastNameValidator(value: string) {
        this.setState({ lastName: value })
        if (value?.length === 0) {
            this.setState((previous: S) => {
                return { ...previous, inputFieldError: { ...previous.inputFieldError, "Last Name": "is Required" } }
            })
        }
        else if (!(/^[a-zA-Z\s]+$/.test(value))) {
            this.setState((previous: S) => {
                return { ...previous, inputFieldError: { ...previous.inputFieldError, "Last Name": "should have only alphabets" } }
            })
        } 

        else if (value?.length > 25) {
            this.setState((previous: S) => {
                return { ...previous, inputFieldError: { ...previous.inputFieldError, "Last Name": "should have max 25 characters" } }
            })
        }

        else {
            this.setState((previous: S) => {
                return { ...previous, inputFieldError: { ...previous.inputFieldError, "Last Name": "" } }
            })
        }
    }

    mobileNoValidator(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target?.value;
        const inputElement = event?.target;
        const selectionStart = inputElement?.selectionStart || 0
    
        this.setState({ mobileNo: value });
    
        if (value) {
            const numericValue = value.replace(/\D/g, "");
            const truncatedValue = numericValue.slice(0, 12);
            let formattedValue = truncatedValue.slice(0, 3) + (truncatedValue.length > 3 ? "-" + truncatedValue.slice(3, 5) : "") + (truncatedValue.length > 5 ? "-" + truncatedValue.slice(5) : "");
            if (!/^971-\d{2}-\d{7}$/.test(formattedValue)) {
                this.setState((prev: S) => ({
                    ...prev,
                    inputFieldError: {
                        ...prev.inputFieldError,
                        "Phone number": "must be in the format 971-xx-xxxxxxx, where 'xx' represents digits."
                    }
                }));
            } else {
                this.setState((prev: S) => ({
                    ...prev,
                    inputFieldError: { ...prev.inputFieldError, "Phone number": "" }
                }))
            }
            return  this.setState({ mobileNo: formattedValue }, () => {
                    const addedChars = formattedValue.length - value.length;
                    const newCursorPosition = selectionStart + addedChars;
        
                    if (inputElement.setSelectionRange) {
                        inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
                    }
                });
            
        } else {
            this.setState((prev: S) => ({
                ...prev,
                inputFieldError: { ...prev.inputFieldError, "Phone number": "is Required" },
                mobileNo: '' 
            }));
        }
        
    }
    
    


    cityValidator(value: string) {
        this.setState({ city: value })
        if (value.length === 0) {
            this.setState((prevStat: S) => {
                return { ...prevStat, inputFieldError: { ...prevStat.inputFieldError, "City": "is Required" } }
            })
        } 

        else if (!(/^[a-zA-Z\s]+$/).test(value)) {
            this.setState((prevStat: S) => {
                return { ...prevStat, inputFieldError: { ...prevStat.inputFieldError, "City": "should have only alphabets" } }
            })
        }

        else if (value.length > 15) {
            this.setState((prevStat: S) => {
                return { ...prevStat, inputFieldError: { ...prevStat.inputFieldError, "City": "should have max 15 characters" } }
            })
        }  

        else if (value.length > 0 && value.length < 15) {
            this.setState((prevStat: S) => {
                return { ...prevStat, inputFieldError: { ...prevStat.inputFieldError, "City": "" } }
            })
        }
    
    }
    stateValidator(value: string) {
        this.setState({ state: value })
        if (value.length === 0) {
            this.setState((prevState: S) => {
                return { ...prevState, inputFieldError: { ...prevState.inputFieldError, "State": "is Required" } }
            })
        } 
        else this.setState((prevState: S) => {
            return { ...prevState, inputFieldError: { ...prevState.inputFieldError, "State": "" } }
        })
    }
    zipValidator(value: string) {
        this.setState({ zip: value })
        if (value.length === 0) {
            this.setState((prev: S) => {
                return { ...prev, inputFieldError: { ...prev.inputFieldError, "Zip code": "is Required" } }
            })
        } 

        else if (!/^[0-9]{5}/.test(value)) {
            this.setState((prev: S) => {
                return { ...prev, inputFieldError: { ...prev.inputFieldError, "Zip code": "should be 5 digits only" } }
            })
        }

        else if (value.length > 5) {
            this.setState((prev: S) => {
                return { ...prev, inputFieldError: { ...prev.inputFieldError, "Zip code": "not valid" } }
            })
        }
        else {
            this.setState((prev: S) => {
                return { ...prev, inputFieldError: { ...prev.inputFieldError, "Zip code": "" } }
            })
        }
    }

    addressValidator(value: string) {
        this.setState({ address: value })
        if (value.length === 0) {
            this.setState((pState: S) => {
                return { ...pState, inputFieldError: { ...pState.inputFieldError, "Address 1": "is Required" } }
            })
        }
         
        else {
            this.setState((pState: S) => {
                return { ...pState, inputFieldError: { ...pState.inputFieldError, "Address 1": "" } }
            })
        }
    }
    address2Validator(value: string) {
        this.setState({ address2: value })
        if (value?.length === 0) {
            this.setState((stat: S) => {
                return { ...stat, inputFieldError: { ...stat.inputFieldError, "Address 2": "is Required" } }
            })
        }
        else if (!(/^(?=.*[a-zA-Z])\S.*\S$/.test(value))) {
            this.setState((stat: S) => {
                return { ...stat, inputFieldError: { ...stat.inputFieldError, "Address 2": "should be alphanumeric with no leading or trailing spaces" } }
            })
        }
        else if (value?.length > 50) {
            this.setState((stat: S) => {
                return { ...stat, inputFieldError: { ...stat.inputFieldError, "Address 2": "should have max 50 characters" } }
            })
        } 
        else {
            this.setState((stat: S) => {
                return { ...stat, inputFieldError: { ...stat.inputFieldError, "Address 2": "" } }
            })
        }
    }

    handleInputChange = (inputValue: unknown, fieldName: string) => {
        const value = inputValue?.target?.value
        if ("First Name" === fieldName) {
            this.fullNameValidator(inputValue.target.value)
        }
        if ("Last Name" === fieldName) {
            this.lastNameValidator(inputValue?.target?.value)
        }
        if ("Address 1" === fieldName) {
            this.addressValidator(inputValue?.target?.value)
        }
        if ("Address 2" === fieldName) {
            this.address2Validator(inputValue?.target?.value)
        }
        if ("Phone number" === fieldName) {
            this.mobileNoValidator(inputValue)
        }

        if ("City" === fieldName) {
            this.cityValidator(inputValue?.target?.value)
        }
        if ("State" === fieldName) {
            this.stateValidator(inputValue?.target?.value)
        }
        if ("Zip code" === fieldName) {
            this.zipValidator(value)
        }

    }

    getToken = async () => {
        const token = GetCookies("ba");
        this.setState({ token })
    }

    handleAddressSaveValidator() {
        const { firstName, lastName, address, address2, mobileNo, zip, city, state, } = this.state
        console.log("Mobile Number", mobileNo)
        if (!firstName) {
            this.setState((prev: S) => {
                return { ...prev, inputFieldError: { ...prev.inputFieldError, "First Name": "is Required" } }
            })
        }
        if (!lastName) {
            this.setState((prev: S) => {
                return { ...prev, inputFieldError: { ...prev.inputFieldError, "Last Name": "is Required" } }
            })
        }
        if (!address) {
            this.setState((prev: S) => {
                return { ...prev, inputFieldError: { ...prev.inputFieldError, "Address 1": "is Required" } }
            })
        }

        if (!address2) {
            this.setState((prev: S) => {
                return { ...prev, inputFieldError: { ...prev.inputFieldError, "Address 2": "is Required" } }
            })
        }

        if (mobileNo.length === 0) {
            this.setState((prev: S) => {
                return { ...prev, inputFieldError: { ...prev.inputFieldError, "Phone number": "is Required" } }
            })
        }

        if (!zip) {
            this.setState((prev: S) => {
                return { ...prev, inputFieldError: { ...prev.inputFieldError, "Zip code": "is Required" } }
            })
        }

        if (!city) {
            this.setState((prev: S) => {
                return { ...prev, inputFieldError: { ...prev.inputFieldError, "City": "is Required" } }
            })
        }
        if (!state) {
            this.setState((prev: S) => {
                return { ...prev, inputFieldError: { ...prev.inputFieldError, "State": "is Required" } }
            })
        }


    }

    handleAddressSave() {
        let isValid
        let isValid2
        const { firstName, lastName, mobileNo, city, state, defaultAddress, inputFieldError, address, address2, zip } = this.state
        const allInputFieldValue = [firstName, lastName, mobileNo,address, address2, city, state, defaultAddress, inputFieldError]

        
        isValid = Object.values(inputFieldError).every((element:string) => element?.length > 0 ? false : true)
        isValid2 = allInputFieldValue.every((element:string) => element?.length === 0 ? false : true)
        
        this.handleAddressSaveValidator()

        if (isValid && isValid2) {
            const headers = { "Content-Type": configJSON.contentTypeApiGetUserProfile, token: this.state.token };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiUpdateBuyerProfileCallId = requestMessage.messageId;
            
            if (Boolean(this.props.singleAddressInfo.attributes)) {

                requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/accounts/${this.state.userId}/user_delivery_addresses/${this.props.singleAddressInfo.id}`);
                requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestBodyMessage),
                    JSON.stringify({
                        data: {
                            attributes: {
                                first_name: firstName,
                                last_name: lastName,
                                phone_number: mobileNo,
                                address_1: address,
                                address_2: address2,
                                city: city,
                                state: state,
                                zip_code: zip,
                                is_default: this.state.checkBox
                            },
                        },
                    }),
                );
                requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchMethod);


            } else {
                requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/accounts/${this.state.userId}/${configJSON.userDeliveryAddresses}`);
                requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestBodyMessage),
                    JSON.stringify({
                        data: {
                            attributes: {
                                first_name: firstName,
                                last_name: lastName,
                                phone_number: mobileNo,
                                address_1: address,
                                address_2: address2,
                                city: city,
                                state: state,
                                zip_code: zip,
                                is_default: this.state.checkBox,
                            },
                        },
                    }),
                );
                requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.callTypeApiValidateMobileNo);
            }

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }

    }

    async componentDidMount(): Promise<void> {
        await this.getToken();
        this.userIdGetter(this.props.userId)
    }

    handleCheckBox = () => {
        this.setState({checkBox : !this.state.checkBox})
    }

    setPhoneNumber = (input:string) => {
        const inputNumber = input;

        if (inputNumber.indexOf('-') === -1) {
            const countryCode = inputNumber.slice(0, 3);
            const regionCode = inputNumber.slice(3, 5);
            const phoneNumber = inputNumber.slice(5);
    
            const formattedNumber = `${countryCode}-${regionCode}-${phoneNumber}`;
            return formattedNumber
        } 
        else return inputNumber
    }

    handlePlacesAutoCompleteChange = (address: string) => {
        this.setState({ address: address });
      };
    
    handlePlacesAutoCompleteSelect = (address:string) => {
        this.setState({ address: address });
        const geocoder = new google.maps.Geocoder()
        geocoder
        .geocode({ address: address})
        .then((response: unknown) => {
            if (response.results[0]) {
                const location = response.results[0].geometry.location;
                const latitude = location.lat();
                const longitude = location.lng();
                this.setState({ 
                locationAddress: response.results[0].formatted_address,
                mapCenter: { lat: latitude, lng: longitude },
                mapCoOrdinates: { lat: latitude, lng: longitude }
                });
                this.setState((pState: S) => {
                    return { ...pState, inputFieldError: { ...pState.inputFieldError, "Address 1": "" } }
                })
            }
        })
      .catch((error: string) => {
        this.setState({addressErr: error})
      })
      };

      setFields = (isSet:boolean) => {
        return  isSet ? 12 : 6
      }

      getErrColor = (isError: boolean) => {
        return isError ? "red" : ""
      }

      getStateErr = (isError: boolean) => {
        return isError ? "#f44336" : ""
      }

      handleZipKeyDown = (event:any, label: string) => {
        if ((label === 'Zip code') && !/^\d$/.test(event.key) && event.key !== 'Backspace') {
        event.preventDefault();
    }}

}

